<!--
    Created by 程雨喵'mac on 2024/1/5.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：设置-费率配置
-->
<style lang="less">
</style>

<template>
  <div>
    <Form v-if="model.ratioConfig">
      <FormItem
        v-for="(item, i) in formItems"
        :key="i"
        :label="item.label + ':'"
        :label-width="item.labelWidth"
        :required="item.isRequired"
      >
        <span style="margin-right: 8px">费率</span>
        <InputNumber
          v-model="model.ratioConfig[item.key]"
          :max="100"
          :min="0"
          :placeholder="item.placeholder"
          style="width: 120px"
          @input="checkSubmit"
        />
        <span style="margin-left: 8px">%</span>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import PcApiSet from '@/request/api/PcApiSet';
import {
  PropertyCompanyRatioConfigModel
} from '../../model/PropertyCompanyDataModel';
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      formItems: [
        { label: '支付渠道服务费费率', labelWidth: 165, placeholder: '可输入0至100', key: 'payChannelRatio', isRequired: true },
        { label: '系统服务费', labelWidth: 165, placeholder: '可输入0至100', key: 'systemServiceRatio', isRequired: true }
      ]
    };
  },
  created () {
    if (this.model.ratioConfig) {
      this.$nextTick(() => {
        this.checkSubmit();
      });
    } else {
      this.requestData();
    }
  },
  methods: {
    checkSubmit () {
      const item = this.formItems.find(v => !this.model.ratioConfig[v.key] && this.model.ratioConfig[v.key] !== 0);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    },
    requestData () {
      const api = PcApiSet.pcRatioConfigInfo;
      api.params = {
        pcId: this.model.id
      };
      this.$http(api).then(res => {
        this.model.ratioConfig = PropertyCompanyRatioConfigModel.initModelWithData(res.data);
        this.model.ratioConfig.propertyCompanyId = this.model.propertyCompanyId;
        this.checkSubmit();
      });
    }
  }
};
</script>

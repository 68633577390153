<!--
    Created by 程雨喵'mac on 2024/1/4.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：设置-结算账号号设置
-->
<style lang="less">
.property-company-settle-account {
  .input-tip {
    margin-top: 5px;
    color: @textColorLight;
    line-height: 12px;
    font-size: 12px;
  }
}
</style>

<template>
  <div class="property-company-settle-account">
    <Alert show-icon>
      账户用途：用于服务商提现计算打款的账户。
    </Alert>
    <!--{{ model.settleAccount }}-->
    <Form v-if="model.settleAccount">
      <FormItem
        v-for="(item, i) in formItems"
        :key="i"
        :label="item.label + ':'"
        :label-width="item.labelWidth"
        :required="item.isRequired"
      >
        <RadioGroup
          v-if="item.key === 'accountType'"
          v-model="model.settleAccount.accountType"
          @on-change="checkSubmit"
        >
          <Radio
            v-for="(sub, r) in accountTypeOptions"
            :key="'r-' + r"
            :label="sub.value"
          >
            <span>{{ sub.label }}</span>
          </Radio>
        </RadioGroup>
        <yg-region-cascader
          v-else-if="item.key === 'h5Area'"
          v-model="model.settleAccount[item.key]"
          :level="3"
          style="width: 100%"
        />
        <Input
          v-else
          v-model.trim="model.settleAccount[item.key]"
          :placeholder="item.placeholder"
          @input="checkSubmit"
        />
        <p
          v-if="item.tip"
          class="input-tip"
        >
          {{ item.tip }}
        </p>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import PcApiSet from '@/request/api/PcApiSet';
import { PropertyCompanySettleAccountModel } from '../../model/PropertyCompanyDataModel';
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      formItems: [
        { label: '账户类型', labelWidth: 145, placeholder: '', key: 'accountType', isRequired: true },
        { label: '开户人名称', labelWidth: 145, placeholder: '请输入物业公司的名称', key: 'accountName', isRequired: true },
        { label: '开户所在地', labelWidth: 145, placeholder: '请选择开户所在地', key: 'h5Area', isRequired: true, tip: '精确到省份/城市' },
        { label: '开户银行全称', labelWidth: 145, placeholder: '请输入物业公司的开户行名称', key: 'bankName', isRequired: true },
        { label: '银行卡号', labelWidth: 145, placeholder: '请输入银行卡号', key: 'cardNo', isRequired: true }
      ],
      accountTypeOptions: [
        { value: 1, label: '对公账户' },
        { value: 2, label: '对私账户' }
      ]
    };
  },
  created () {
    console.log(this.model);
    if (!this.model.settleAccount) {
      this.requestSettleInfo();
    } else {
      this.checkSubmit();
    }
  },
  methods: {
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model.settleAccount[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    },
    requestSettleInfo () {
      const api = PcApiSet.pcSettleAccountInfo;
      api.params = {
        pcId: this.model.propertyCompanyId
      };
      this.$http(api).then(res => {
        this.model.settleAccount = PropertyCompanySettleAccountModel.initModelWithData(res.data);
        this.model.settleAccount.propertyCompanyId = this.model.propertyCompanyId;
        this.checkSubmit();
      });
    }
  }
};
</script>

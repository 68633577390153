<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：组织管理-物业公司管理
-->
<style lang="less">
</style>

<template>
  <section>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:propertyCompany:query'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
    <!--弹窗：新增(基础信息)-->
    <v-base-info-submit
      v-if="!!dialog.baseInfoModel"
      :model="dialog.baseInfoModel"
      :info-type="3"
      @on-close="dialog.baseInfoModel = null"
      @on-sure="onSureBaseInfo"
    />
    <!--弹窗：设置-->
    <v-setting-submit
      v-if="!!dialog.settingModel"
      :is-step-mod="dialog.settingIsStepMod"
      :index="dialog.settingIndex"
      :model="dialog.settingModel"
      @on-close="onCloseSubmit"
    />
  </section>
</template>

<script>
import PcApiSet from '@/request/api/PcApiSet';
import { PropertyCompanyListModel, PropertyCompanyDetailModel } from './model/PropertyCompanyDataModel';
import ColumnMixin from './mixin/tableColumn';
import vSettingSubmit from './view/setting/index.vue';
import vBaseInfoSubmit from '@/pages/020__organizationManager/_components/baseInfo.vue';
export default {
  components: { vBaseInfoSubmit, vSettingSubmit },
  mixins: [ColumnMixin],
  data () {
    return {
      testLoading: false,
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle').setPermission('system:propertyCompany:add'),
        BaseSettingModel.initData('刷新', 'refresh', 'md-refresh').setPermission('system:propertyCompany:query')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        baseInfoModel: null,
        settingModel: null,
        settingIsStepMod: false,
        settingIndex: 0
      }
    };
  },
  created () {
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    // 新增数据
    onSureBaseInfo () {
      const res = this.dialog.baseInfoModel.checkValidAdditionSubmit();
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestAdd(res.params);
      }
    },
    onCloseSubmit (needRefresh) {
      if (needRefresh) {
        this.onChangePage(1);
      }
      this.dialog.settingIsStepMod = false;
      this.dialog.settingModel = null;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.baseInfoModel = PropertyCompanyDetailModel.createAdditionModel();
        break;
      case 'refresh':
        this.onChangePage(1);
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = PcApiSet.pcList;
      api.params = {
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = PropertyCompanyListModel.initListWithDataList(resData.rows);
      });
      // const resList = ServiceDealerListModel.createFakeDataList();
      // console.log(resList);
      // this.page.total = resList.length;
      // this.tableData = ServiceDealerListModel.initListWithDataList(resList);
    },
    requestAdd (p) {
      const api = PcApiSet.pcAdd;
      api.params = p;
      this.$http(api).then(res => {
        this.dialog.baseInfoModel = null;
        this.$Message.success('操作成功');
        this.onChangePage(1);
        this.$store.dispatch('checkPermission', [
          'system:propertyCompany:admin:set',
          'system:propertyCompany:contact:set',
          'system:propertyCompany:settleAccount:set',
          'system:propertyCompany:ratioConfig:set'
        ]).then(hasPermission => {
          this.dialog.settingIsStepMod = hasPermission;
          if (hasPermission) {
            this.requestDetail(res.data.propertyCompanyId);
            // this.dialog.settingModel = PropertyCompanyDetailModel.initModelWithData(res.data);
          }
        });
      });
    },
    requestChangeStatus (row, status) {
      const api = PcApiSet.pcChangeStatus;
      api.params = {
        id: row.propertyCompanyId,
        status: status ? 1 : 0
      };
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        row.status = status ? 1 : 0;
        row.h5StatusSwitch = status;
      }).catch(e => {
        row.status = status ? 0 : 1;
        row.h5StatusSwitch = !status;
      });
    },
    requestDetail (id) {
      const api = PcApiSet.pcInfo;
      api.params = { id };
      this.$http(api).then(res => {
        this.dialog.settingModel = PropertyCompanyDetailModel.initModelWithData(res.data);
      });
      // this.dialog.settingModel = row;
      // const resData = DemoDetailModel.createFakeData();
      // this.dialog.detailModel = DemoDetailModel.initModelWithData(resData);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>

import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isNumber, isValidString1, isPhone, isAlphanumeric } from '@/libs/regularUtils';
import md5 from 'md5';

export class PropertyCompanyRatioConfigModel extends BaseModel {
  constructor () {
    super();
    this.propertyCompanyId = ''; // 物业公司ID
    this.payChannelRatio = ''; // 支付渠道服务费费率
    this.systemServiceRatio = ''; // 系统服务费
  }

  /**
   * 数据具体校验-费率校验
   * @returns {*}
   */
  checkValidRatioConfigSubmit () {
    return BaseModel.getValidRes(true, '', {
      propertyCompanyId: this.propertyCompanyId,
      payChannelRatio: this.payChannelRatio,
      systemServiceRatio: this.systemServiceRatio
    });
  }
}

export class PropertyCompanySettleAccountModel extends BaseModel {
  constructor () {
    super();
    this.propertyCompanyId = ''; // 物业公司ID
    this.accountName = ''; // 开户人名称
    this.accountType = ''; // 账户类型（1：对公账户 2：对私账户）
    this.accountsBank = ''; // 开户银行
    this.bankName = ''; // 开户银行全称
    this.cardNo = ''; // 银行卡号
    this.province = ''; // 省
    this.city = ''; // 市
    this.district = ''; // 区
    this.h5Area = [];
  }

  initData (resData) {
    super.initData(resData);
    if (this.province && this.city) {
      this.h5Area = [this.province, this.city];
    }
    return this;
  }

  /**
   * 数据具体校验-结算账号
   * @returns {*}
   */
  checkValidSettleAccountSubmit () {
    // else if (this.bankName.length < 6 || this.bankName.length > 40) {
    //   return BaseModel.getValidRes(false, '开户行全称称请输入6-40位');
    // } else if (!isValidString1(this.bankName)) {
    //   return BaseModel.getValidRes(false, '开户行全称称请输入有效字符');
    // }
    // 专项校验
    if (this.accountName.length < 1 || this.accountName.length > 40) {
      return BaseModel.getValidRes(false, '开户人名称请输入1-40位');
    } else if (!isValidString1(this.accountName)) {
      return BaseModel.getValidRes(false, '开户人名称请输入有效字符');
    } else if (this.cardNo.length > 40) {
      return BaseModel.getValidRes(false, '银行卡号请输入40位以内');
    } else if (!isNumber(this.cardNo)) {
      return BaseModel.getValidRes(false, '银行卡号请输入有效字符内');
    } else if (this.h5Area.length !== 2) {
      return BaseModel.getValidRes(false, '开户行所在地有误');
    }
    return BaseModel.getValidRes(true, '', {
      propertyCompanyId: this.propertyCompanyId,
      accountType: this.accountType,
      accountName: this.accountName,
      accountsBank: this.accountsBank,
      bankName: this.bankName,
      cardNo: this.cardNo,
      province: this.h5Area[0],
      city: this.h5Area[1]
    });
  }
}

export class PropertyCompanyListModel extends BaseModel {
  constructor () {
    super();
    this.id = ''; // 物业公司ID
    this.propertyCompanyName = ''; // 物业公司名称
    this.propertyCompanyId = ''; // 物业公司编码
    this.propertyCompanyCode = ''; // 物业公司编码
    this.serviceProviderName = ''; // 服务商名称(上级)
    this.serviceProviderId = ''; // 服务商ID(上级)
    this.principalType = ''; // 主体类型 主体类型(1：公司/企业, 2：小微, 3：个人)
    this.principalTypeName = ''; // 主题类型名称
    this.adminUser = null; // 登录账号对象

    this.contact = ''; // 联系人
    this.mobile = ''; // 联系人电话
    // this.communityCount = 0; // 小区数量
    // this.siteCount = 0; // 点位数量
    // this.deviceCount = 0; // 充电桩数量
    this.status = ''; // 状态 0: 停用，1：启用
    this.createTime = ''; // 创建时间
    // 前端自有
    this.h5StatusSwitch = false;
    this.h5AdminUserName = ''; // 服务商管理员登录账号
    this.h5AdminCanChange = true; // 只有没有设置过登录账号时，才可以设置
  }

  initData (resData) {
    super.initData(resData);
    this.h5StatusSwitch = !!this.status;
    this.h5AdminUserName = resData.adminUser ? resData.adminUser.username : '';
    this.h5AdminCanChange = !this.h5AdminUserName;
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: '000101010' + i,
        serviceProviderName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 20) + 10),
        companyName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 20) + 10),
        providerCode: BaseModel.getFakeNumber(99999999, 0),
        principalType: i % 2 + 1,
        principalTypeName: i % 2 ? '公司企业' : '个人',
        adminUser: {
          userName: '登录账号'
        },
        contact: BaseModel.getFakeTitle(5),
        mobile: '139042880' + (BaseModel.getFakeNumber(80, 0) + 10),
        status: i % 2,
        createTime: '2023-12-31 12:59:59'
      };
      list.push(dic);
    }
    return list;
  }
}
export class PropertyCompanyDetailModel extends PropertyCompanyListModel {
  constructor () {
    super();
    this.password = ''; // 登录密码(编辑账号时使用)
    this.settleAccount = null; // 结算账户对象 (PropertyCompanySettleAccountModel)
    this.ratioConfig = null; // 公司费率对象 (PropertyCompanySettleAccountModel)
    this.h5PasswordAgain = ''; // 确认密码时使用
  }

  initData (resData) {
    super.initData(resData);
    this.settleAccount = PropertyCompanySettleAccountModel.initModelWithData(resData.settleAccount);
    this.settleAccount.propertyCompanyId = this.propertyCompanyId;
    this.ratioConfig = PropertyCompanyRatioConfigModel.initModelWithData(resData.ratioConfig);
    this.ratioConfig.propertyCompanyId = this.propertyCompanyId;
    return this;
  }

  /**
   * 数据具体校验-新增
   * @returns {*}
   */
  checkValidAdditionSubmit () {
    // 专项校验
    if (this.providerName.length < 6 || this.providerName.length > 40) {
      return BaseModel.getValidRes(false, '请输入6-40位服务商的名称');
    } else if (!isValidString1(this.providerName)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    }

    return BaseModel.getValidRes(true, '', {
      providerName: this.providerName,
      principalType: this.principalType,
      ownerId: 1 // 默认填写1
    });
  }

  /**
   * 数据具体校验-账号设置
   * @returns {*}
   */
  checkValidAccountSubmit () {
    // 专项校验
    if (this.h5AdminUserName.length < 6 || this.h5AdminUserName.length > 20) {
      return BaseModel.getValidRes(false, '请输入6-20位登录账号');
    } else if (isNumber(this.h5AdminUserName)) {
      return BaseModel.getValidRes(false, '账号不可纯数字');
    } else if (!isAlphanumeric(this.password)) {
      return BaseModel.getValidRes(false, '6~8位密码，必须为数字字母组合');
    } else if (this.password !== this.h5PasswordAgain) {
      return BaseModel.getValidRes(false, '两次输入的密码不同，请重新输入');
    }
    return BaseModel.getValidRes(true, '', {
      ownerId: this.propertyCompanyId,
      adminUserName: this.h5AdminUserName,
      password: md5(this.password)
    });
  }

  /**
   * 数据具体校验-联系人
   * @returns {*}
   */
  checkValidConcatSubmit () {
    // 专项校验
    if (!isPhone(this.mobile)) {
      return BaseModel.getValidRes(false, '手机号码不合规，请重新输入');
    } else if (this.contact.length < 1 || this.contact.length > 20) {
      return BaseModel.getValidRes(false, '联系人请输入1-20位');
    } else if (!isValidString1(this.contact)) {
      return BaseModel.getValidRes(false, '联系人请输入有效字符');
    }
    return BaseModel.getValidRes(true, '', {
      ownerId: this.propertyCompanyId,
      contact: this.contact,
      mobile: this.mobile,
      captcha: this.code,
      uuid: ''
    });
  }

  static createFakeData () {
    return this.createFakeDataList(1)[0];
  }

  static createAdditionModel () {
    return new this();
  }
}

/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 物业公司相关请求
 */

import BaseApi from '@/request/base/BaseApi';

class PcApiSet {
  constructor () {
    this.pcList = BaseApi.createModel('获取物业公司信息列表', '/opmp/pc/list').setMethod(0);
    this.pcChangeStatus = BaseApi.createModel('启用禁用物业公司', '/opmp/pc/changeStatus').setMethod(2);
    this.pcAdd = BaseApi.createModel('新增物业公司信息', '/opmp/pc/add');
    this.pcInfo = BaseApi.createModel('获取物业公司详情', '/opmp/pc/info').setMethod(0, false);
    this.pcAdminSet = BaseApi.createModel('设置服物业公司理员-登录账号', '/opmp/pc/admin/set');
    this.pcConcatSet = BaseApi.createModel('设置物业公司管理员-联系人', '/opmp/pc/contact/set');
    this.pcSettleAccountSet = BaseApi.createModel('设置物业公司管理员-结算账户', '/opmp/pc/settleAccount/set');
    this.pcSettleAccountInfo = BaseApi.createModel('获取物业公司结算账户', '/opmp/pc/settleAccount/info').setMethod(0, false);
    this.pcRatioConfigSet = BaseApi.createModel('设置物业公司费率', '/opmp/pc/ratioConfig/set');
    this.pcRatioConfigInfo = BaseApi.createModel('设置物业公司费率', '/opmp/pc/ratioConfig/info').setMethod(0, false);
  }
}

export default new PcApiSet();
